/* eslint-disable import/prefer-default-export */
const pic1 = require('../Assets/Ventilator.jpg');
const pic2 = require('../Assets/O2_concentrator.png');
// const pic3 = require('../Assets/la.jpg');

export const SLIDE_INFO = [
  { id: 1, title: 'chicago', image: pic1 },
  { id: 2, title: 'la', image: pic2 },
  // { id: 3, title: 'ny', image: pic3 },
];
